import { goodsSkuPage } from "@/api/goods/goods"
import { mapGetters } from "vuex"
import { tree,goodsCategoryInfo,attrbuteClassValue } from "@/api/goods/goodscategory"
import { addCollect, deleteCollect, isCollect } from "@/api/goods/goods_collect"
import { apiHotSearchWords  } from '@/api/pc';
 
export default {
    data: () => {
        return {
            cargoList: [],
            shopList: [], // 店铺列表
            brandList: [], // 品牌列表
            attributeList: [], // 属性列表
            brandInitialList: [],
            currentInitial: "", // 当前选择品牌分区
            choosedBrand: "", // 已选择的品牌,
            hasChoosedAttrs: false, // 忆选择的属性
            total: 0,
            keyword: "",
            catewords:'',
            currentPage: 1,
            pageSize: 12,
            is_free_shipping: 0,
            is_own: "",
            filters: {
                site_id: 0,
                category_id: 0,
                category_level: 0,
                brand_id: 0,
                min_price: "",
                max_price: "",
                order: "",
                sort: "desc",
                coupon: 0
            },
            loading: true,
            whetherCollection: 0,
			first_index:0,
			two_index: 0,
			first_child_list: [],
			first_index_active: 1,
			testingNumberState: false,
			hotSearchWords: [], 
			allcategory:[],
			curcatelist:[],
			curcatelist3:[],
			curcatelist4:[],
			showchilde:false,
			showchilde3:false,
			showchilde4:false,
			curcateid:0,
			curcateid2:0,
			curcateid3:0,
			curcateid4:0,
			selectedattr:[]
        }
    },
    created() {
        this.keyword = this.$route.query.keyword || ""
		if( this.$route.query.keyword ) window.document.title = `${this.$route.query.keyword} - ${this.siteInfo.site_name}`

        this.filters.category_id = this.$route.query.category_id || ""
		this.curcateid = this.$route.query.category_id || ""
        this.filters.category_level = this.$route.query.level || ""
        this.filters.brand_id = this.$route.query.brand_id || ""
        this.filters.coupon = this.$route.query.coupon || 0
        this.getGoodsList();
        if (this.$route.query.category_id) {
			this.first_index_active=this.$route.query.category_id;
            this.categorySearch()
        }
		this.getattrvalue();
		this.getHotSearchWords(); 
    },
    computed: {
        ...mapGetters(["defaultGoodsImage", "siteInfo"])
    },
    methods: {
        /**
         * 商品分类搜索
         */ 
        categorySearch() {
            goodsCategoryInfo({
                category_id: this.filters.category_id,
                goodstype: 0
            }).then(res => {
				 console.log(JSON.stringify(res),'=======1=================')
                if (res.code == 0 && res.data) {
                    this.catewords = res.data.category_full_name
					this.first_index = res.data.category_id_1
					this.two_index = res.data.category_id_2
					this.first_child_list = res.data.child_list
					this.allcategory=res.data.alltree;
					//console.log(JSON.stringify(this.allcategory),'========================')
					 this.first_index_active= res.data.topid;
					 
					window.document.title = `${res.data.category_name} - ${this.siteInfo.site_name}`
                }
                
            }).catch(err => {
console.log(err,'==========2=============')
            })
        },
		showcurchild(cid){
			this.curcateid=cid;
			
			// this.curcatelist=[];
			// this.showchilde=false;
			// this.curcatelist3=[];
			// this.showchilde3=false;
			// this.curcatelist4=[];
			// this.showchilde4=false;
			
			// this.allcategory.forEach(item => {
			//     if (item.category_id==cid) {
			//        this.curcatelist=item.child_list;
			//     }
			// });
			// if(this.curcatelist&&this.curcatelist.length>0){
			// 	this.showchilde=true;
			// }else{
			// 	this.showchilde=false;
			// }
			 //console.log(".,.,.,.,.,",JSON.stringify(this.curcatelist));
		},
		showcurchild2(cid){ 
			this.curcatelist3=[];
			this.showchilde3=false;
			this.curcatelist4=[];
			this.showchilde4=false;
			this.curcateid2=cid;
			this.allcategory.forEach(item => {
				item.child_list.forEach(item2=>{
					if (item2.category_id==cid) {
					   this.curcatelist3=item2.child_list;
					}
				});
			    
			});
			if(this.curcatelist3&&this.curcatelist3.length>0){
				this.showchilde3=true;
			}else{
				this.showchilde3=false;
			}
			 //console.log(".,.,.,.,.,",JSON.stringify(this.curcatelist));
		},
		showcurchild3(cid){ 
			this.curcatelist4=[];
			this.showchilde4=false;
			this.curcateid3=cid;
			console.log(".,.,.,.,.,",cid);
			this.allcategory.forEach(item => {
				item.child_list.forEach(item2=>{
					if(item2.child_list&&item2.child_list.length>0){
						item2.child_list.forEach(item3=>{
							if (item3.category_id==cid) {
							   this.curcatelist4=item3.child_list;
							}
						});
					}
					
					
				});
			    
			});
			if(this.curcatelist4&&this.curcatelist4.length>0){
				this.showchilde4=true;
			}else{
				this.showchilde4=false;
			}
			console.log(".,.,.,.,.,",this.showchilde4);
			 //console.log(".,.,.,.,.,",JSON.stringify(this.curcatelist));
		}, 
		showhidecate2(e){
			//根据鼠标位置判断是否隐藏 <289就是鼠标移动到上面了
			 
			//如果下级有信息 就不隐藏
			if(this.curcatelist3&&this.curcatelist3.length>0){
				this.showchilde=true;
			}else{
				this.showchilde=false;
			}
			//会出现最后一层打开 但是鼠标移到之前
			if(this.curcatelist4&&this.curcatelist4.length>0){
				this.showchilde4=true;
			}else{
				this.showchilde4=false;
			}
			if(e.y<=289){
							 this.showchilde=false;
							 this.showchilde3=false;
							 this.showchilde4=false;
			}
		},		
		showhidecate3(e){
			 
			//如果下级有信息 就不隐藏
			if(this.curcatelist4&&this.curcatelist4.length>0){
				this.showchilde3=true;
			}else{
				this.showchilde3=false; 
			}
			if(e.y<=289&&e.x>459){
							 this.showchilde=false;
							 this.showchilde3=false;
							 this.showchilde4=false;
			}
			
		},		
		showhidecate4(e){   
			
			this.showchilde4=false;
			if(e.y<=289){
							 this.showchilde=false;
							 this.showchilde3=false;
							 this.showchilde4=false;
			}
		},
		openurl(cid,level){
			//console.log(".,.,.,.,.,",cid+"-"+level);
			this.showchilde=false;
			this.showchilde3=false;
			this.showchilde4=false;
			
			this.$router.push({
				path: '/list',
				query: {
					category_id:cid,
					level:level
				}
			}); 
			 
		},
		getattrvalue(){
			attrbuteClassValue({
                attr_class_id: 3
            }).then(res => { 
                this.attributeList=res.data;
                
            }).catch(err => {

            })
		},
		search() {
			this.showchilde=false,
			this.showchilde3=false,
			this.showchilde4=false;
			 this.$router.push({
				path: '/list',
				query: {
					category_id:this.filters.category_id,
					level:this.filters.category_level,
					keyword: this.keyword ? this.keyword : this.defaultSearchWords
				}
			}); 
		},
		totrade(skuid){
			this.$router.push({ path: '/spotgoods', query: { category_id: 0, level: 1,gid:skuid } })
		},
		getHotSearchWords() {
			apiHotSearchWords({}).then(res => { 
				if (res.code == 0 && res.data.words) {
					this.hotSearchWords = res.data.words.split(',');
				}
			});
		},
		setFiindex( item ) {
			console.log(item,'setFiindex')
			this.first_index_active = item.category_id
			window.document.title = `${item.category_name} - ${this.siteInfo.site_name}`
		},
		setSiindex() {
			this.first_index_active = 0
		},
        addToCart(item) {
            this.$store
                .dispatch('cart/add_to_cart', item)
                .then(res => {
                    var data = res.data
                    if (data > 0) {
                        this.$message({
                            message: "加入购物车成功",
                            type: "success"
                        })
                    }
                })
                .catch(err => err);
        },
        async isCollect(item) {
            await isCollect({ goods_id: item.goods_id }).then(res => {
                this.whetherCollection = res.data
                if(this.whetherCollection == 0){
                    item.isCollection = false
                }else{
                    item.isCollection = true
                }
            })
                .catch(err => err);
        },
        async editCollection(item) {
           await this.isCollect(item)
            const { goods_id, sku_id, site_id, sku_name, sku_price, sku_image } = item;
            if (this.whetherCollection == 0) {
                await addCollect({ goods_id, sku_id, site_id, sku_name, sku_price, sku_image })
                    .then(res => {
                        this.$message({
                            message: '收藏成功',
                            type: 'success'
                        });
                        item.isCollection = true
                    })
                    .catch(err => err);
            } else {
                await deleteCollect({
                    goods_id
                }).then(res => {
                    if (res.data > 0) {
                        this.$message({
                            message: '取消收藏成功',
                            type: 'success'
                        });
                        item.isCollection = false
                    }
                })
                    .catch(err => err);
            }
        },
        getGoodsList() {
			
            const currentArr = []
			 
            if (this.attributeList) {
                this.attributeList.forEach(item => {
                    if (item.child) {
                        item.child.forEach(subitem => {
                            if (subitem.selected) { 
                                currentArr.push({
                                    attr_id: item.attr_id,
                                    attr_value_id: subitem.attr_value_id
                                })
                            }
                        })
                    }
                })
            }

            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                site_id: this.filters.siteId,
                keyword: this.keyword,
				goodstype:0,
                attr: currentArr.length > 0 ? JSON.stringify(currentArr) : "",
                ...this.filters
            }
			//console.log("------------------",JSON.stringify(params));
            goodsSkuPage(params || {})
                .then(res => {
					//console.log(res,'goodsSkuPage')
                    const { count, page_count, list } = res.data
					//console.log("------------------",JSON.stringify(list));
                    this.total = count
                    this.cargoList = list
                    this.loading = false
                })
                .catch(err => {
                    this.loading = false
                })
        },


        onChooseBrand(brand) {
            this.choosedBrand = brand
            this.filters.brand_id = brand.id
            this.getGoodsList()
        },

        setMuiltChoose(attr) {
            this.$set(attr, "isMuiltSelect", !attr.isMuiltSelect)
            this.getGoodsList()
        },

        setAttrSelected(item, subitem) {
            if (item.child) {
                item.child.forEach(attr => {
                    this.$set(attr, "selected", false)
                })
            }

            this.$set(subitem, "selected", true)
            this.$set(item, "selectedValue", subitem.attr_value_name)
			//
		    let addindex=this.selectedattr.findIndex(fitem => {
		    			  if(fitem.item.attr_id  == item.attr_id){
		    				  return true;
		    			  }
		    });
			if(addindex<0){
				this.selectedattr.push({
				                        item: item,
				                        subitem: subitem 
				                    });
			}else{
				//单选需要更新
				this.selectedattr[addindex].subitem=subitem;
			}
			
			//console.log(",,,,,,,,,,,,,,,,",JSON.stringify(this.selectedattr));
            this.getGoodsList()
        },
		delselect(aitem,asubitem){
			
		  let delindex=this.selectedattr.findIndex(fitem => {
			  if(fitem.subitem.attr_value_id == asubitem.attr_value_id){
				  return true;
			  }
		  });
		   
		  this.selectedattr.splice(delindex,1);	
		  
		  this.$set(asubitem, "selected", false)
		  //this.$set(item, "selectedValue", subitem.attr_value_name)
		  var selectedValueArray = []
		  let selectedValue = aitem.selectedValue || ""
		  selectedValueArray = selectedValue.split(",")
		  if (selectedValueArray[0] == "") selectedValueArray.pop(0)
		  
		  if (selectedValueArray.indexOf(asubitem.attr_value_name) !== -1) {
		      selectedValueArray.splice(selectedValueArray.indexOf(asubitem.attr_value_name), 1)
		  }
		  this.$set(aitem, "selectedValue", selectedValueArray.toString())
		  this.getGoodsList()
		},
        setAttrSelectedMuilt(item, subitem) {
			
			
			let delindex=this.selectedattr.findIndex(fitem => {
						  if(fitem.subitem.attr_value_id  == subitem.attr_value_id){
							  return true;
						  }
			});
			
			
            this.$set(subitem, "selected", !subitem.selected)
            var selectedValueArray = []

            if (subitem.selected) {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) == -1) {
                    selectedValueArray.push(subitem.attr_value_name)
                }
				//增加顶部的选择
				if(delindex<0){
					this.selectedattr.push({
					                        item: item,
					                        subitem: subitem 
					                    });
				} 
				
            } else {
                const selectedValue = item.selectedValue || ""
                selectedValueArray = selectedValue.split(",")
                if (selectedValueArray[0] == "") selectedValueArray.pop(0)

                if (selectedValueArray.indexOf(subitem.attr_value_name) !== -1) {
                    selectedValueArray.splice(selectedValueArray.indexOf(subitem.attr_value_name), 1)
                }
				
				//删除顶部的选择
				this.selectedattr.splice(delindex,1);	
            }

            this.$set(item, "selectedValue", selectedValueArray.toString())
            this.getGoodsList()
        },

        colseBrand() {
            this.choosedBrand = ""
            this.filters.brand_id = ""
            this.getGoodsList()
        },

        colseAttr(item) {
            item.selectedValue = ""
            item.child.forEach(subitem => {
                subitem.selected = false
            })

            item.isMuiltSelect = false

            this.getGoodsList()
        },

        handlePageSizeChange(size) {
            this.pageSize = size
            this.getGoodsList()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.getGoodsList()
        },
        handlePriceRange() {
			if( Number(this.filters.min_price )> Number(this.filters.max_price )){
				// es6解构赋值
				[ this.filters.min_price , this.filters.max_price ] = [ this.filters.max_price , this.filters.min_price ]
			} 
			 
            this.getGoodsList()
        },
        handleChangeInitial(initial) {
            this.currentInitial = initial
        },
        changeSort(type) {
            if (this.filters.order === type) {
                this.$set(this.filters, "sort", this.filters.sort === "desc" ? "asc" : "desc")
            } else {
                this.$set(this.filters, "order", type)
                this.$set(this.filters, "sort", "desc")
            }

            this.getGoodsList()
        }
    },
    watch: {
        is_free_shipping: function (val) {
            this.filters.is_free_shipping = val ? 1 : ""
            this.getGoodsList()
        },
        is_own: function (val) {
            this.filters.is_own = val ? 1 : ""
            this.getGoodsList()
        },
        $route: function (curr) {
			 
			if( curr.query.keyword ) window.document.title = `${curr.query.keyword} - ${this.siteInfo.site_name}`
			if(curr.query.level){
				this.filters.category_level = curr.query.level
				this.filters.category_id = curr.query.category_id
				this.getGoodsList()
				if(curr.query.level != 3){
					this.categorySearch()
				}
			}
            if (curr.query.category_id == undefined) {
                this.catewords = "$route$route"
                this.currentPage = 1
                this.keyword = curr.query.keyword
                this.filters.category_id = curr.query.category_id || ""
                this.filters.category_level = curr.query.level || ""
                this.filters.brand_id = curr.query.brand_id || ""
                this.getGoodsList()
            }
        }
    }
}
