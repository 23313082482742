<template>
	
	
	<div class="goods-list" v-loading="loading">
		<div class="in-sousuo">
			<div class="sousuo-box">
				 
				<input type="text"   v-model="keyword" @keyup.enter="search" maxlength="50" />
				<el-button class="serbtn" type="primary" size="small" @click="search">搜索</el-button>
			</div>
			<div class="hot-search-words" v-if="hotSearchWords.length">
				 
				<ul>
					<li v-for="(item, index) in hotSearchWords" :key="index" @click="keyword=item;search();">
						{{ item }}
					</li>
				</ul>
			</div>
		</div>
		
		<div v-if=" first_child_list " class="top_cate_class"> 
			<ul v-if="0"> 
				<li :class="{active: item.category_id == first_index_active}"  @click="setFiindex(item)" v-for="item in first_child_list" v-if="item.level==1">
					<router-link  :to="{ path: '/list', query: { category_id: item.category_id, level: item.level } }">{{item.category_name}}</router-link>
				</li>
			</ul>
		</div>
		
		<!-- 搜索关键字 -->
		<div class="search_bread" v-if="keyword&&0">
			<router-link to="/">首页</router-link>
			<span >/</span>
			<span class="keyword">{{ keyword}}</span>
			<span class="selectedattr"  v-for="(attritem,attrindex) in selectedattr">{{attritem.item.attr_name}}-{{attritem.subitem.attr_value_name}}
			 <span @click="delselect(attritem.item,attritem.subitem)">X</span>
			</span>
		</div>
		<div class="search_bread" v-else-if="!keyword && catewords &&0">
			<router-link to="/">首页</router-link>
			<span>/</span>
			 
			<span >{{catewords}}</span>
			 <span class="selectedattr "  v-for="(attritem,attrindex) in selectedattr">{{attritem.item.attr_name}}-{{attritem.subitem.attr_value_name}}
			  <span @click="delselect(attritem.item,attritem.subitem)">X</span>
			 </span>
			 
		</div>
		 

		<!-- 品牌过滤记录区 -->
		<!-- <div class="attr_filter" v-if="catewords">
			<el-tag type="info" v-if="choosedBrand" closable @close="colseBrand" effect="plain">
				<span v-if="choosedBrand" class="title">品牌：</span>
				{{ choosedBrand.brand_name }}
			</el-tag>
			<span v-for="item in attributeList" :key="item.attr_id">
				<el-tag type="info" v-if="item.selectedValue" closable @close="colseAttr(item)" effect="plain">
					<span class="title" v-if="item.attr_name">{{ item.attr_name }}：</span>
					{{ item.selectedValue }}
				</el-tag>
			</span>
		</div> -->

		<!-- 品牌属性筛选区 -->
		<template>
			<div class="category" v-if="brandList.length || attributeList.length">
				<!-- 品牌 -->
				<!-- <div class="brand">
					<div class="table_head">品牌：</div>
					<div class="table_body">
						<div class="initial">
							<span type="info" effect="plain" hit @mouseover="handleChangeInitial('')">所有品牌</span>
							<span type="info" effect="plain" hit v-for="item in brandInitialList" :key="item" @mouseover="handleChangeInitial(item)">{{ (item || '').toUpperCase() }}</span>
						</div>
						<div class="content">
							<el-card v-for="item in brandList" :key="item.id" body-style="padding: 0;height: 100%;" shadow="hover" v-show="currentInitial === '' || item.brand_initial === currentInitial"
							 class="brand-item">
								<el-image :src="$img(item.image_url || defaultGoodsImage)" :alt="item.brand_name" :title="item.brand_name" fit="contain"
								 @click="onChooseBrand(item)" />
							</el-card>
						</div>
					</div>
				</div> -->
                <div class="categorylist">
					<div class="cltitle">交易品种</div>
					<div class="allcategory" >
						 <div class="cateitem" :class="curcateid==cateitem.category_id?'active':''" v-for="(cateitem,cateindex) in allcategory" :key="cateindex" @mouseover="showcurchild(cateitem.category_id)" @click="openurl(cateitem.category_id,cateitem.level)">  
							  
								<span>
									<img :src="$img(cateitem.image)"/>
								</span>
							   <span>{{cateitem.category_name}}</span>
							  <!-- <i class="el-icon-arrow-right"></i> -->
							 
						</div>
					</div>
					<div class="categorychilds" v-if="showchilde==true"  @mouseleave="showhidecate2($event)">
						<div class="cateitem" :class="curcateid2==curitem2.category_id?'active':''" v-for="(curitem2,curindex2) in curcatelist" v-if="curcatelist.length>0" :key="curindex2" @mouseover="showcurchild2(curitem2.category_id)" @click="openurl(curitem2.category_id,curitem2.level)">
							   
							   <span>{{curitem2.category_name}}</span>
							   <i class="el-icon-arrow-right"></i>
							 
						</div> 
					</div>
					<div class="categorychilds3" v-if="showchilde3==true"  @mouseleave="showhidecate3($event)">
						<div class="cateitem" :class="curcateid3==curitem3.category_id?'active':''" v-for="(curitem3,curindex3) in curcatelist3" v-if="curcatelist3.length>0" :key="curindex3" @mouseover="showcurchild3(curitem3.category_id)" @click="openurl(curitem3.category_id,curitem3.level)">
							  
								 
							   <span>{{curitem3.category_name}}</span>
							   <i class="el-icon-arrow-right"></i>
							 
						</div>
					  
					</div>
					<div class="categorychilds4" v-if="showchilde4==true"  @mouseleave="showhidecate4($event)">
						<div class="cateitem" :class="curcateid4==curitem4.category_id?'active':''" v-for="(curitem4,curindex4) in curcatelist4" v-if="curcatelist4.length>0" :key="curindex4"  @mouseover="curcateid4=curitem4.category_id"  @click="openurl(curitem4.category_id,curitem4.level)">
							   
							   <span>{{curitem4.category_name}}</span> 
							 
						</div>
					  
					</div>
					
				</div>
				
				<div class="brandlist"> 
				<!-- 属性 -->
				<div class="brand" v-for="item in attributeList" :key="'attr' + item.attr_id">
					<div class="table_head">{{ item.attr_name }}：</div>
					<div class="table_body">
						<div class="content">
							<span v-for="subitem in item.child" :key="subitem.attr_value_id">
								<el-checkbox :label="subitem.attr_value_name" v-if="item.isMuiltSelect" :checked="subitem.selected" @change="setAttrSelectedMuilt(item, subitem)"></el-checkbox>
								<el-link :class="subitem.selected?'active':''" :underline="false" v-else @click="setAttrSelected(item, subitem)">{{ subitem.attr_value_name }}</el-link>
							</span>
						</div>
					</div>
					<div class="table_op">
						<el-button size="small" icon="el-icon-circle-plus-outline" @click="setMuiltChoose(item)">多选</el-button>
					</div>
				</div>
				
				</div>
			</div>
		</template>

		<div class="list-wrap">
			<div class="goods-recommended" v-if="cargoList.length&&0">
				<goods-recommend :page-size="cargoList.length < 5 ? 2 : 5" />
			</div>
			<div class="list-right">
				<!-- 排序筛选区 -->
				<div>
					<div class="sort">
						<div class="item" @click="changeSort('')" v-if="0">
							<div class="item-name">综合</div>
						</div>
						<div class="item" @click="changeSort('sale_num')" v-if="0">
							<div class="item-name">销量</div>
							<i v-if="filters.order === 'sale_num' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
							<i v-else class="el-icon-arrow-up el-icon--up" />
						</div>
						<div class="item" @click="changeSort('discount_price')" v-if="0">
							<div class="item-name">价格</div>
							<i v-if="filters.order === 'discount_price' && filters.sort === 'desc'" class="el-icon-arrow-down el-icon--down" />
							<i v-else class="el-icon-arrow-up el-icon--up" />
						</div>
						<div class="item-other" v-if="0">
							<el-checkbox label="包邮" v-model="is_free_shipping"></el-checkbox>
						</div>
						<!-- <div class="item-other">
							<el-checkbox label="自营" v-model="is_own"></el-checkbox>
						</div> -->
						<div class="input-wrap"  v-if="0">
							<div class="price_range">
								<el-input placeholder="最低价格" size="small" v-model="filters.min_price"></el-input>
								<span>—</span>
								<el-input placeholder="最高价格" size="small" v-model="filters.max_price"></el-input>
							</div>
							<el-button plain size="mini" @click="handlePriceRange">确定</el-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		
		
		<div class="cargo-list" v-if="cargoList.length">
			
			<div class="goods-info"> 
				<div class="item" v-for="(item, index) in cargoList" :key="item.goods_id" >
					<img class="img-wrap" :src="$img(item.sku_image, { size: 'mid' })" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })" @error="imageError(index)" />
					<div class="price-wrap" v-if="0">
						<div class="price">
							<p>￥</p>
							{{ showPrice(item) }}
							<div class="price-icon-wrap">
								<img v-if="item.member_price && item.member_price == showPrice(item)" :src="$img('upload/uniapp/index/VIP.png')"/>
								<img v-else-if="promotion_type==1" :src="$img('upload/uniapp/index/discount.png')"/>
							</div>
						</div>
						<div class="market-price">￥{{ item.market_price }}</div>
					</div>
					<div class="goods-name" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">
					   {{ item.goods_name }}
					     <span>{{item.catename1}}</span>						
						 <span>/&nbsp;&nbsp;{{item.catename2}}</span>
					    <span v-for="(aitem,aindex) in item.attrres" :key="aindex" v-if="aitem.attr_name=='货源级别'||aitem.attr_name=='加工方式'||aitem.attr_name=='种植基地'">/&nbsp;&nbsp;{{aitem.attr_value_name}}</span>
					   
					</div>
					<div class="goods-name" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">{{ item.introduction }}</div>
					<div class="goods-name"> 
						<el-button class="serbtn" type="primary" size="small" @click="$router.pushToTab({ path: '/sku-' + item.sku_id })">详情</el-button>
						<el-button class="serbtn" type="primary" size="small" @click="totrade(item.sku_id)">交易</el-button>
					</div>
					 
					<!-- <div class="shop_name">{{item.site_name}}</div> -->
					<div class="saling" v-if="0">
						<div class="free-shipping" v-if="item.is_free_shipping == 1">包邮</div>
						<!-- <div class="is_own" v-if="item.is_own == 1">自营</div> -->
						<div class="promotion-type" v-if="item.promotion_type == 1">限时折扣</div>
					</div>
				</div>
		
		
		
		
			</div>
			<div class="pager">
				<el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="currentPage"
				 :page-size.sync="pageSize" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange"
				 hide-on-single-page></el-pagination>
			</div>
		</div>
		<div class="empty" v-else>
			<div class="ns-text-align">没有找到您想要的商品。换个条件试试吧</div>
		</div>
		
		
	</div>
</template>

<script>
	import BreadCrumbs from "@/components/BreadCrumbs";
	import GoodsRecommend from "@/components/GoodsRecommend";
	import list from "./list";

	export default {
		name: "list",
		components: {
			BreadCrumbs,
			GoodsRecommend
		},
		data: () => {
			return {};
		},
		mixins: [list],
		created() {},
		methods: {
			showPrice(data){
				let price = data.discount_price;
				if(data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price;
				return price;
			}
		}
	};
</script>

<style lang="scss" scoped>  
	.padd-10{
		padding: 0 10px;
	}
	.keyword{
		margin-right:10px;
	}
	.selectedattr{
		color:#FF0036;
		margin-right:5px;
		span{
			cursor:pointer;
		}
	}
	.goods-list {
		background: #fff;
		 
		padding-top: 10px;
		.top_cate_class{
			margin-top:10px;
			position:absolute; 
			left:0;
			border-bottom:solid 3px #61433c;
		    z-index:9;
			width:100%;
			 ul{
				 width:1200px;
				 margin: 0px auto;  
				 li{
				 	display: inline-block;
				 	height:30px;
				 	line-height:30px;
				 	padding: 0 10px; 
				 }
				 .active{
				 	color:#61433c;
				 	background-color: #e5e0df;
				 }
			 }
			
			
		}
	}

	.search_bread {
		display: inline-block;
		font-size: 12px;
		color:#999999;
		border-bottom:solid 1px #dddddd;
		margin: 0px auto;
		margin-top:40px;
		margin-bottom:20px;
		width: 100%;
		padding: 10px;
		p{
			float: left;
		}
		a{
			color:#999999;
		}
		li{
			float: left;
			padding: 0 10px;
		}
		.active a{
			color:#ff547b !important;
		}
	}

	.selected_border {
		border: 1px solid $base-color;
	}

	.attr_filter {
		.el-tag {
			margin-right: 5px;
			margin-bottom: 10px;
			border-radius: 0;

			.title {
				color: $base-color;
				font-size: 15px;
			}
		}
	}

	.category {
		margin: 0 auto 10px auto;
		margin-top:40px;
		border: 1px solid #eeeeee;
        .categorylist{
			width:13%; 
			float:left;
			display: block;
			background-color: #e5e5e5;
			.cltitle{
				font-weight:bolder;
				font-size:16px;
				height:35px;
				line-height: 35px;
				text-align: center;
			}
			.cateitem{ 
				cursor: pointer;
				height:82px;
				line-height: 82px;
				padding-left:30px;
				img{
					width:35px;
					height:35px;
					border-radius: 5px;
				}
			    span{margin-right:10px;}
			}
			.active{
				cursor: pointer;
				background:#ffffff;
			}
			.categorychilds{
				position: absolute;
				z-index: 999;
				background:#e5e5e5;
				border:solid 1px #f1f1f1;
				width:160px;
				height:280px;
				 
				left:20.5%;
				top:290px;
				.catelist2{
					background:#999999;
					display: inline-block;
					cursor:pointer;
					margin-right:10px;
					.fname{
						padding:3px 11px;
						 router-link{
							color:#ffffff;
						} 
					}
					.catelist3{
						position: absolute;
						top:80px;
						left:30px;
						color:#333333; 
						&:last-child {
							top:120px;
						}
					}
					
					.catelist4{
						display:inline-block;
						color:#333333;
						margin-left:10px;
					}
				}
			} 
			.categorychilds3{
				position: absolute;
				z-index: 999;
				background:#e5e5e5;
				border:solid 1px #f1f1f1;
				width:160px;
				height:280px;
				 
				left:460px;
				top:290px;
				.catelist2{
					background:#999999;
					display: inline-block;
					cursor:pointer;
					margin-right:10px;
					.fname{
						padding:3px 11px;
						 router-link{
							color:#ffffff;
						} 
					}
					.catelist3{
						position: absolute;
						top:80px;
						left:30px;
						color:#333333; 
						&:last-child {
							top:120px;
						}
					}
					
					.catelist4{
						display:inline-block;
						color:#333333;
						margin-left:10px;
					}
				}
			}
			.categorychilds4{
				position: absolute;
				z-index: 999;
				background:#e5e5e5;
				border:solid 1px #f1f1f1;
				width:160px;
				height:280px;
				 
				left:620px;
				top:290px;
				.catelist2{
					background:#999999;
					display: inline-block;
					cursor:pointer;
					margin-right:10px;
					.fname{
						padding:3px 11px;
						 router-link{
							color:#ffffff;
						} 
					}
					.catelist3{
						position: absolute;
						top:80px;
						left:30px;
						color:#333333; 
						&:last-child {
							top:120px;
						}
					}
					
					.catelist4{
						display:inline-block;
						color:#333333;
						margin-left:10px;
					}
				}
			}
		}
		.brandlist{
			margin-left:5px;
			display: block;
			width:86%;
			float:left;
		}
		.brand {
			border-bottom: 1px solid #eeeeee; 
			display: flex;
			flex-direction: row;
			 

			.table_head {
				width: 140px;
				min-width: 140px;
				border-right: 1px solid #eeeeee;
				padding-left: 10px;
				padding-top: 10px;
				background-color: #f2f6fc;
			}

			.table_body {
				flex-grow: 1;

				.initial {
					margin: 5px auto 10px 10px;

					span {
						border: 0;
						margin: 0;
						padding: 5px 10px;
						border: 0;
						border-radius: 0;

						&:hover {
							background-color: $base-color;
							color: #ffffff;
						}
					}
				}

				.content {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					padding: 10px;
					width: 700px;
                    .active{
						color:#FF0036;
					}
					.brand-item {
						margin-right: 10px;
						margin-top: 10px;
					}

					.el-card {
						width: 125px;
						height: 45px;

						&:hover {
							border: 1px solid $base-color;
							cursor: pointer;
						}
					}

					span {
						margin: auto 25px;
					}
				}
			}

			.table_op {
				margin-top: 5px;
				margin-right:  5px;
			}

			.el-image {
				width: 100%;
				height: 100%;
			}
		}
	}

	.list-wrap {
		overflow: hidden;
	}

	.goods-recommended {
		width: 200px;
		background-color: #fff;
		float: left;
		margin-right: 10px;
	}

	.sort {
		display: flex;
		align-items: center;

		.item {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;

			&:hover {
				background: $base-color;
				color: #fff;
			}
		}

		.item-other {
			display: flex;
			align-items: center;
			padding: 5px 15px;
			border: 1px solid #f1f1f1;
			border-left: none;
			cursor: pointer;
		}

		.input-wrap {
			display: flex;
			align-items: center;

			.price_range {
				margin-left: 360px;
			}

			span {
				padding-left: 10px;
			}

			.el-input {
				width: 150px;
				margin-left: 10px;
			}

			.el-button {
				margin: 0 17px;
			}
		}

		>div:first-child {
			border-left: 1px solid #f1f1f1;
		}
	}
.cargo-list{
	width:100%;
	float:left;
}
	.goods-info {
		margin-top: 5px;
		display: flex;
		flex-wrap: wrap;

		.item {
			width:100%;
			margin: 10px 20px 0 0;
			border: 1px solid #eeeeee;
			padding-top:5px;
			position: relative; 

			&:nth-child(4n) {
				margin-right: initial !important;
			}

			&:hover {
				border: 1px solid $base-color;
			}

			.img-wrap {
				width: 178px;
				height: 178px;
				cursor: pointer;
				padding: 10px;
				float:left;
				border-radius:25px;
			}

			.goods-name {
				padding: 0 10px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				word-break: break-all;
				height: 50px;
				cursor: pointer;

				&:hover {
					color: $base-color;
				}
				 button{
					float:right;
					margin-right:20px;
				}
				span{
					margin-left:5px;
					color:#61433c;
				}
				 
			}

			.price-wrap {
				padding: 0 10px;
				display: flex;
				align-items: center;

				.price {
					display: flex;
					color: $base-color;
					font-size: $ns-font-size-lg;

					p {
						font-size: $ns-font-size-base;
						display: flex;
						align-items: flex-end;
					}
				}

				.market-price {
					color: #838383;
					text-decoration: line-through;
					margin-left: 10px;
				}
			}

			.sale-num {
				padding: 0 10px;
				display: flex;
				color: #838383;
				font-size: $ns-font-size-sm;

				p {
					color: #4759a8;
				}
			}

			.shop_name {
				padding: 0 10px;
				display: flex;
				color: #838383;
			}

			.saling {
				padding: 0 10px;
				display: flex;
				font-size: $ns-font-size-sm;
				line-height: 1;

				.free-shipping {
					background: $base-color;
					color: #ffffff;
					padding: 3px 4px;
					border-radius: 2px;
					margin-right: 5px;
				}

				.is_own {
					color: #ffffff;
					background: #ff850f;
					border: 1px solid;
					margin-right: 5px;
					display: flex;
					align-items: center;
					padding: 3px 4px;
					border-radius: 2px;
				}

				.promotion-type {
					color: $base-color;
					border: 1px solid $base-color;
					display: flex;
					align-items: center;
					padding: 1px 3px;
				}
			}

			.item-bottom {
				display: flex;
				margin-top: 5px;

				.collection {
					flex: 1;
					border: 1px solid #e9e9e9;
					border-right: none;
					border-left: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}

				.cart {
					flex: 2;
					border: 1px solid #e9e9e9;
					border-right: none;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
				}
			}
		}
	}
	.price-icon-wrap{
		display: inline-block;
		max-width: 35px;
		margin-left: 3px;
		line-height: 1;
		margin-top: 4px;
		img{
			max-width: 100%;
		}
	}

	.empty {
		margin-top: 45px;
	}

	.pager {
		text-align: center;
		margin-top: 30px;
	}
	
	.in-sousuo {
		width: 700px;
		padding-top: 20px;
		margin:auto; 
	 
		.sousuo-box {
			width: 100%;
			height: 46px;
			line-height:46px;
			border: 1px solid #61433c;
			box-sizing: border-box;
	        border-radius: 10px;
			.el-dropdown {
				padding: 0 10px;
				cursor: pointer;
	
				&::after {
					content: '';
					border-left: 1px solid #cfcfcf;
					margin-left: 5px;
				}
			}
	        .serbtn{
				background-color: #61433c;
				border-radius: 10px;
			    border: 3px solid #61433c;
				width:80px;
				height:45px;
				font-size:15px;
			}
			input {
				width: 380px;
				height: 22px;
				background: none;
				outline: none;
				border: none;
				margin: 4px;
			}
	
			button {
				border-radius: 0;
				float: right;
			}
		}
	
		.hot-search-words {
			width: 100%;
			height: 20px;
			margin-top: 10px;
			font-size: 14px;
	 
	
			ul {
				overflow: hidden;
				margin: 0;
				height: 21px;
				padding: 0;
				color: $base-color-info;
	
				li {
					cursor: pointer;
					list-style: none;
					float: left;
					margin-right: 15px;
	
					&:hover {
						color: $base-color;
					}
				}
			}
		}
	}
	
</style>
